// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Icon } from '@/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { SearchResult } from '@/search/SearchResult.molecule';
import { TextButton } from '@/core/TextButton.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { canModifyWorkbook } from '@/services/authorization.service';
import { sqLicenseManagementStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { SearchModes, SearchPanes } from '@/search/search.constants';
import { ASSET_GROUP_VIEW } from '@/worksheet/worksheet.constants';
import { clearSelection, getStoreForPane, setMode } from '@/search/search.actions';
import { setReturnViewKey, setView } from '@/worksheet/worksheet.actions';
import { LOAD_STATUS } from '@/workbook/workbook.constants';

interface SearchGroupIF {
  loadStatus: string;
  label: string;
  mode: SearchModes;
  searchMode: SearchModes;
  searchTypes: string[];
  scopeIds: string[];
  restrictExploration: boolean;
  onErrorClick: () => void;
  onClickItem: (item) => void;
  items: any[];
  pane: SearchPanes;
  indicateSelection?: boolean;
  selectedItemId?: string;
  searchResultIcons: (item) => Promise<JSX.Element | void>;
  noItemsMessage?: string;
  isSelectingAsset?: boolean;
}

export const SearchGroup: React.FunctionComponent<SearchGroupIF> = ({
  items,
  loadStatus,
  label,
  mode,
  pane,
  onErrorClick,
  onClickItem,
  searchResultIcons,
  searchMode,
  searchTypes,
  scopeIds,
  restrictExploration,
  noItemsMessage,
  indicateSelection = false,
  selectedItemId,
  isSelectingAsset = false,
}) => {
  const { t } = useTranslation();
  const isAssetGroupView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key === ASSET_GROUP_VIEW.key);
  const store = getStoreForPane(pane);
  const selectedItemIds = useFluxPath(store, () => store.selectedItemIds);
  const showCreateAssetGroupButton =
    searchMode === 'asset-groups' &&
    sqLicenseManagementStore.hasAssetGroups() &&
    pane === 'main' &&
    canModifyWorkbook(sqWorkbookStore.workbook);
  const searchingUsages = mode === 'usages';

  const renderAssetGroupButton = (
    <TextButton
      testId="createButton"
      label="SEARCH_DATA.CREATE"
      onClick={() => {
        setReturnViewKey(sqWorksheetStore.view.key);
        setView(ASSET_GROUP_VIEW.key);
      }}
      disabled={isAssetGroupView}
      size="sm"
      extraClassNames="sq-btn-xs pr5 mr5"
    />
  );

  const nonClickableHeading = (
    <span className="flexColumnContainer flexSpaceBetween flexAlignCenter heading text-interactive fs15">
      {t(`SEARCH_DATA.${label}`)}
      {showCreateAssetGroupButton && renderAssetGroupButton}
    </span>
  );

  return (
    <div>
      {loadStatus === LOAD_STATUS.LOADING && !searchingUsages && (
        <div>
          {nonClickableHeading}
          <div className="pt10 pb10 flexNoGrowNoShrink flexColumnContainer flexCenter">
            <IconWithSpinner spinning={true} large={true} />
            <span className="mb5 ml5 mt5">{t(`SEARCH_DATA.LOADING_${label}`)}</span>
          </div>
        </div>
      )}

      {loadStatus === LOAD_STATUS.ERROR && !searchingUsages && (
        <div>
          {nonClickableHeading}
          <div className="flexColumnContainer sq-text-danger cursorPointer pt3 pb6" onClick={onErrorClick}>
            <Icon icon="fa-exclamation-triangle" type="danger" extraClassNames="flexCenter pl5 pr10 pt2" />
            <div className="flexFill pl5">
              {t(`SEARCH_DATA.LOAD_${label}_FAILED`)}
              <br />
              <span className="text-underline">{t('SEARCH_DATA.RETRY')}</span>
            </div>
          </div>
        </div>
      )}

      {items.length === 0 &&
        ((mode === searchMode && !searchingUsages) || mode === 'overview') &&
        noItemsMessage &&
        pane === 'main' && (
          <div>
            {nonClickableHeading}
            <div className="m5">{t(noItemsMessage)}</div>
          </div>
        )}

      {items.length > 0 && (mode === searchMode || mode === 'overview') && (
        <div>
          {mode === 'overview' && (
            <div className="flexColumnContainer flexSpaceBetween flexAlignCenter heading">
              <span
                className="text-interactive flexFill fs15"
                onClick={() => setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}>
                {t(`SEARCH_DATA.${label}`)}
              </span>
              {showCreateAssetGroupButton ? (
                renderAssetGroupButton
              ) : (
                <Icon
                  icon="fa-chevron-right"
                  extraClassNames="text-interactive pl5 pr5"
                  onClick={() => setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}
                />
              )}
            </div>
          )}

          {_.map(items, (item: any) => (
            <SearchResult
              isSelectingAsset={isSelectingAsset}
              key={item.id}
              item={item}
              items={items}
              searchResultIcons={searchResultIcons}
              itemSelected={_.indexOf(selectedItemIds, item.id) > -1}
              onClickItem={() => {
                clearSelection(); // unselect selected items
                onClickItem(item);
                setReturnViewKey(sqWorksheetStore.view.key);
              }}
              isSelected={indicateSelection && selectedItemId === item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

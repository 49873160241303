// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@/core/Icon.atom';
import { UnitOfMeasure } from '@/core/UnitOfMeasure.atom';
import { onEnterKeypress } from '@/core/onEnterKeypress.util';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { Checkbox } from '@/core/Checkbox.atom';
import { useIsAssetGroupView } from './hooks/useIsAssetGroupView.hook';
import { isAsset, isDatafile, isDisplay, isStringSeries, itemIconClass } from '@/utilities/utilities';
import { useAsyncEffect, useDidUpdate } from 'rooks';
import { sqAssetGroupStore } from '@/core/core.stores';
import { onEventExceptDisplayDropdown } from '@/displays/displays.utilities';
import { clearSelection, toggleSelectItem } from '@/search/search.actions';

interface SearchResultProps {
  searchResultIcons: (item) => Promise<JSX.Element | void>;
  item: any;
  items?: any[];
  customIconClasses?: string;
  onClickItem: (item) => void;
  showItemSelection?: boolean;
  isSelectingAsset?: boolean;
  itemSelected?: boolean;
  isSelected?: boolean;
  isAllUnselectable?: boolean;
  highlightItem?: boolean;
}

export const SearchResult: React.FunctionComponent<SearchResultProps> = ({
  searchResultIcons,
  item,
  items,
  onClickItem,
  customIconClasses,
  isSelectingAsset = false,
  itemSelected = false,
  isSelected = false,
  isAllUnselectable = false,
  showItemSelection = false,
  highlightItem = false,
}) => {
  const itemIconClassNames = `${customIconClasses ? customIconClasses : 'sq-fairly-dark-gray'} pl5 pr10`;
  const arrowIconClassNames = `${customIconClasses ? customIconClasses : 'text-interactive'} mr5`;
  const isEditingAssetGroup = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.id === item.id);
  const { isAssetGroupView } = useIsAssetGroupView();
  const showNavigateCaret = isAsset(item) || isDatafile(item) || item?.hasChildren;
  const isUomRecognized = !_.isNil(item?.valueUnitOfMeasure);
  const uomToDisplay =
    isUomRecognized && !isStringSeries(item) ? item.valueUnitOfMeasure : item.sourceValueUnitOfMeasure;

  const [isItemSelected, setSelected] = useState(false);
  const [icons, setIcons] = useState(null);

  useDidUpdate(() => setSelected(itemSelected), [itemSelected]);

  useAsyncEffect(async () => {
    const newIcons = await searchResultIcons(item);
    setIcons(newIcons);
  }, [searchResultIcons, item]);

  const unfocusSearchResult = (e) => {
    e.target.blur();
  };

  const renderCheckbox = () => {
    if (showItemSelection && (isAssetGroupView || !isAllUnselectable)) {
      return (
        <Checkbox
          id={`select_${item.id}`}
          label=""
          classes={classNames({
            invisible: !isAssetGroupView && (isAsset(item) || isDisplay(item) || item?.hasChildren),
          })}
          isChecked={isItemSelected}
          onClick={() => {
            toggleSelectItem(item.id, items);
            setSelected((isSelected) => !isSelected);
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <HoverTooltip text={isEditingAssetGroup && 'SEARCH_DATA.NO_ACTIONS_WHILE_EDITING'}>
      <div
        tabIndex={0}
        onMouseLeave={unfocusSearchResult}
        className={classNames(
          'flexColumnContainer',
          'itemRow',
          'flexNoGrowNoShrink',
          'cursorPointer',
          'pt3',
          'pb6',
          {
            'disabledItemRow text-italic  disabledLook cursorNotAllowed': isEditingAssetGroup,
          },
          { itemRowSelected: isSelected },
          { highlightFade: highlightItem },
        )}>
        {renderCheckbox()}

        <div
          className="flexColumnContainer flexSpaceBetween flexFill mtn3 pt3 mbn6 pb6"
          onKeyUp={onEnterKeypress(() => !isEditingAssetGroup && onClickItem(item))}
          onClick={onEventExceptDisplayDropdown(() => {
            if (!isEditingAssetGroup) {
              onClickItem(item);
              clearSelection();
            }
          })}>
          <div className="flexColumnContainer flexCenter">
            {isSelectingAsset && icons}
            <Icon
              icon={itemIconClass(item)}
              testId="searchItemIcon"
              type="inherit"
              extraClassNames={itemIconClassNames}
              large={true}
            />
          </div>

          <div className="aggressiveWordBreak flexFill">
            <div className="searchResultName">
              <span>{item.name} </span>
              <UnitOfMeasure
                unitOfMeasure={{
                  value: uomToDisplay,
                  isRecognized: isUomRecognized,
                }}
              />
            </div>
            {item.description && (
              <div className="xsmall pb5" data-testid="searchResultDescription">
                {item.description}
              </div>
            )}
            <div className="xsmall pb2 sq-fairly-dark-gray text-italic">
              {item.assets?.[0]?.formattedName || item?.datasource?.name}
            </div>
          </div>

          <div className="flexRowContainer flexCenter mt2">
            <div className="flexColumnContainer flexCenter">
              {!isSelectingAsset && icons}

              {showNavigateCaret && (
                <Icon icon="fa-chevron-right" type="inherit" extraClassNames={arrowIconClassNames} testId="arrowIcon" />
              )}
            </div>
          </div>
        </div>
      </div>
    </HoverTooltip>
  );
};
